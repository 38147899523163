'use client';

import { memo, useState } from 'react';

import LocalizedMessage from 'components/i18n/LocalizedMessage';
import { LocalizedCurrency } from 'components/i18n/LocalizedMessages';
import { Pill } from 'components/ui/Pill';

import { ContentComponentsMessage, GenericMessage } from 'constants/i18n.messages';

import { ContentComponentProps } from 'types/contentComponent';

import { isNonNullable } from 'utils/array';

import { Box, Card, SLICE } from '@vrfi/web-components';
import { InfoCircleFilledIcon, MealIcon } from 'components/ui/Icons';
import { Modal } from 'components/ui/Modal/Modal';
import RichText from 'content/RichText';
import Image from 'next/image';
import { useIntl } from 'react-intl';
import { getImageProps } from 'types/contentfulUtils';
import { useFeatureFlag } from 'utils/featureFlags';
import styles from './ContentfulMenuList.module.css';

const parsePrice = (price?: string) => {
  return price ? parseFloat(price.replace(/,/, '.')) * 100 : null;
};

const Price = ({ price }: { price?: string }) => {
  const parsedPrice = parsePrice(price);
  if (!parsedPrice) {
    return null;
  }
  return (
    <p data-text="heading3" style={{ '--title-color': 'var(--color-green10)' }}>
      <LocalizedCurrency value={parsedPrice} />
    </p>
  );
};

interface MenuListItemProps {
  item: ContentfulWeb.TypeMenuListItems;
}

/** @private export used for testing */
export const ContentfulMenuListItem = ({ item }: MenuListItemProps) => {
  const { name, subParagraph, normalPrice, matkallaPrice } = item.fields;
  const parsedPrice = parsePrice(normalPrice);
  const parsedMatkallaPrice = parsePrice(matkallaPrice);

  return (
    <article key={item.sys.id} className={styles.menuItem}>
      <div className={styles.content}>
        {subParagraph && <p className={styles.subParagraph}>{subParagraph}</p>}

        <h4 className={styles.title}>{name}</h4>

        <div className={styles.prices}>
          {!!parsedPrice && (
            <div>
              <div className={styles.price}>
                <LocalizedCurrency value={parsedPrice} />
              </div>
              <Pill variant="transparentGreen10" className={styles.pill}>
                <LocalizedMessage id={ContentComponentsMessage.NORMAL_PRICE} />
              </Pill>
            </div>
          )}

          {!!parsedMatkallaPrice && (
            <div>
              <div className={styles.price}>
                <LocalizedCurrency value={parsedMatkallaPrice} />
              </div>
              <Pill variant="transparentPurple20" className={styles.pill}>
                <LocalizedMessage id={ContentComponentsMessage.MATKALLA_PRICE} />
              </Pill>
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

/** @private export used for testing */
export const ContentfulMenuListItemCard = ({ item }: MenuListItemProps) => {
  const { name, subParagraph, normalPrice, matkallaPrice, image, description } = item.fields;
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const infoButtonAriaLabel = formatMessage({ id: GenericMessage.READ_MORE });

  const imageProps = getImageProps(image);

  const parsedMatkallaPrice = parsePrice(matkallaPrice);

  return (
    <Card
      cardLayout="column"
      edge="border"
      image={
        imageProps ? (
          <Image
            alt=""
            {...imageProps}
            sizes={`(max-width: ${SLICE.SM.to}px) 100vw, 348px`}
            data-testid="menu-item-image"
          />
        ) : (
          <div
            data-box="alignCenter justifyCenter"
            style={{ height: '100%', backgroundColor: 'var(--color-grey40)' }}
          >
            <MealIcon size={4.75} color="var(--color-grey17)" />
          </div>
        )
      }
    >
      <div data-box="s04 row alignCenter justifySpaceBetween">
        <h2 data-text="heading4">{name}</h2>
        {!!description && (
          <button
            data-button="circle s02"
            data-canvas="blue10"
            onClick={() => setOpen(true)}
            aria-label={infoButtonAriaLabel}
          >
            <InfoCircleFilledIcon size={2} />
          </button>
        )}
      </div>

      <p data-text="16 mono bold" style={{ '--text-color': 'var(--color-grey17)' }}>
        {subParagraph}
      </p>
      <Price price={normalPrice} />

      {!!parsedMatkallaPrice && (
        <Box as="div" spacing="s08Row">
          <p data-text="heading4" style={{ '--title-color': 'var(--color-purple10)' }}>
            <LocalizedCurrency value={parsedMatkallaPrice} />
          </p>
          <span data-box="alignCenter">
            <Pill data-text="16 mono bold" variant="transparentPurple20">
              <LocalizedMessage uppercase id={ContentComponentsMessage.MATKALLA_PRICE} />
            </Pill>
          </span>
        </Box>
      )}

      {!!description && open && (
        <Modal onClose={() => setOpen(false)} headerContent={name} data-gap="s16">
          {() => (
            <>
              <p data-text="16 mono bold" style={{ '--text-color': 'var(--color-grey17)' }}>
                {subParagraph}
              </p>
              <RichText document={description} />
            </>
          )}
        </Modal>
      )}
    </Card>
  );
};

/** @private export used for testing */
export const CompactListItem = ({
  item: {
    fields: { name, normalPrice },
  },
}: MenuListItemProps) => {
  return (
    <div data-box="s04 row alignCenter justifySpaceBetween">
      <p data-text="heading5">{name}</p>
      <Price price={normalPrice} />
    </div>
  );
};

function ContentfulMenuList(props: ContentComponentProps<'menuList'>) {
  const {
    fields: { menuItems = [], compactList },
  } = props.content;
  const newMenuDesign = useFeatureFlag('newMenuItems');
  const renderMenuItems = menuItems.filter(isNonNullable);
  return !renderMenuItems.length ? null : (
    <section
      className={
        compactList
          ? styles.menuListContainer
          : newMenuDesign
          ? styles.menuCardContainer
          : styles.menuContainer
      }
    >
      {renderMenuItems.map((item) =>
        compactList ? (
          <CompactListItem key={item.sys.id} item={item} />
        ) : newMenuDesign ? (
          <ContentfulMenuListItemCard key={item.sys.id} item={item} />
        ) : (
          <ContentfulMenuListItem key={item.sys.id} item={item} />
        ),
      )}
    </section>
  );
}

export default memo(ContentfulMenuList);
