import { ComponentType, ReactNode } from 'react';
import { clientTemplates } from './clientTemplates';

import { LOCALE } from 'constants/i18n';
import {
  ClientContentComponentContentType,
  ClientContentComponentPropsAnyContentType,
  ContentComponentProps,
} from 'types/contentComponent';

const getContentComponent = <T extends ClientContentComponentContentType>(
  contentType: T,
): ComponentType<ContentComponentProps<T>> | undefined => {
  return clientTemplates[contentType];
};

export const ClientContentComponent: ComponentType<
  ClientContentComponentPropsAnyContentType & { children?: ReactNode; locale: LOCALE }
> = ({ children, ...props }) => {
  const { content } = props;

  const contentType = content.sys.contentType.sys.id;

  const Component = getContentComponent(contentType);

  if (!Component) {
    console.warn(`No content component found for ${contentType}`);
    return null;
  }

  return <Component {...props}>{children}</Component>;
};
