'use client';
import { memo } from 'react';

import utils from '@vrfi/web-components/css/utils.module.css';

import { Text } from 'components/cssModules';

import LocalizedPrice from 'components/i18n/LocalizedPrice/LocalizedPrice';
import HighlightCircle from '../HighlightCircle';
import styles from './PriceHighlightCircle.module.css';

interface Props {
  text?: string | null;
  price?: number | null;
  helperText?: string;
  darkMode?: boolean | null;
}

function PriceHighlightCircle({ darkMode, helperText, price, text, ...props }: Props) {
  const priceInteger = price != null && Math.floor(price);
  const priceDecimal = price != null && (price % 1).toFixed(2).substring(2);
  const highlightContentIsPrice = typeof priceInteger === 'number';
  const circleText = highlightContentIsPrice ? priceInteger : text;

  return (
    <HighlightCircle dark={darkMode || false} className={styles.circle} {...props}>
      {helperText && <Text paragraph={12}>{helperText}</Text>}

      <span className={utils.visuallyHidden}>
        {highlightContentIsPrice ? (
          <LocalizedPrice price={price}>
            {(formatted: string) => formatted.replace(/\u00a0/g, ' ')}
          </LocalizedPrice>
        ) : (
          text
        )}
      </span>

      <div aria-hidden="true" className={styles.content}>
        <Text as="p" title3 className={styles.text}>
          {circleText}
          {typeof priceInteger === 'number' && priceDecimal === '00' ? ' €' : null}
        </Text>

        {!!priceInteger && priceDecimal !== '00' && (
          <div>
            <Text size={12} bold as="div" className={styles.smallText}>
              {priceDecimal}
            </Text>
            <Text size={16} bold as="div" className={styles.smallText}>
              €
            </Text>
          </div>
        )}
      </div>
    </HighlightCircle>
  );
}

export default memo(PriceHighlightCircle);
