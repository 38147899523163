import dynamic from 'next/dynamic';
import { ComponentType } from 'react';
import { ClientContentComponentContentType, ContentComponentProps } from 'types/contentComponent';

export const clientTemplates: {
  [T in ClientContentComponentContentType]: ComponentType<ContentComponentProps<T>>;
} = {
  journeySearchHero: dynamic(() => import('../ContentfulJourneySearchHero')),
  stripeHeadingWithRichText: dynamic(() => import('../ContentfulStripeHeadingWithRichText')),
  foldingList: dynamic(() => import('../ContentfulFoldingList')),
  htmlBlock: dynamic(() => import('../ContentfulHtmlBlock')),
  tripSearch: dynamic(() => import('../ContentfulTripSearch')),
  fullwidthImageWith2To4LinkBoxes: dynamic(
    () => import('../ContentfulFullwidthImageWith2To4LinkBoxes'),
  ),
  multiTicketSearchHero: dynamic(() => import('../ContentfulMultiTicketSearchHero')),
  trafficBulletins: dynamic(() => import('../TrafficBulletins')),
  trainMap: dynamic(() => import('../TrainMap')),
  carbonCalculator: dynamic(() => import('../ContentfulCarbonCalculator')),
  journeyPlanner: dynamic(() => import('../JourneyPlanner')),
  dynamicMap: dynamic(() => import('../DynamicMap')),
  element: dynamic(() => import('../Element')),
};
