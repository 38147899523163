import { ComponentProps } from 'react';

import { Box } from 'components/cssModules';

export const ModalRow = ({
  spacing = 's16Dynamic',
  ...props
}: JSX.IntrinsicElements['div'] & { spacing?: ComponentProps<typeof Box>['spacing'] }) => (
  <Box spacing={spacing}>
    <div {...props} />
  </Box>
);
