'use client';

import { useSiteData } from 'contexts/siteData';
import dynamic from 'next/dynamic';

const CookieConsentSettingsImpl = dynamic(() => import('./CookieConsentSettings'));

export default function CookieConsentSettings({
  pageId,
  isInModal,
}: {
  pageId?: string;
  isInModal?: boolean;
}) {
  const { cookiePolicy } = useSiteData().pages;
  const isCookiePolicyPage = pageId === cookiePolicy.id;
  return isCookiePolicyPage ? <CookieConsentSettingsImpl isInModal={isInModal} /> : null;
}
