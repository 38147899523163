'use client';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo, useState } from 'react';

import routes from 'data/routes.json';

const sortBySlug = (a: { slug: string }, b: { slug: string }) => (a.slug > b.slug ? 1 : -1);

const RouteItem = ({
  route: { slug, template },
}: {
  route: { slug: string; template: string };
}) => (
  <li>
    <Link href={slug}>
      <code>{slug}</code>
      <small> [{template}]</small>
    </Link>
  </li>
);

export const Dev404Page = () => {
  const path = usePathname();
  const [search, setSearch] = useState('');

  const allRoutes = useMemo(
    () =>
      routes
        .flatMap((route) =>
          Object.values(route.slug).map((slug) => ({ template: route.template, slug })),
        )
        .sort(sortBySlug),
    [],
  );

  const visibleRoutes = useMemo(() => {
    return search
      ? allRoutes.filter(
          (route) => route.slug.includes(search) || route.template.toString().includes(search),
        )
      : allRoutes;
  }, [allRoutes, search]);

  return (
    <>
      <h1>Development 404 page</h1>
      <p>
        There is no page at <code>{path}</code> yet.
      </p>
      <h2>Try one of these instead</h2>
      <input
        placeholder="Search for route by url or type"
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
      />
      <ul>
        {visibleRoutes.map((route, i) => (
          <RouteItem key={i} route={route} />
        ))}
      </ul>
    </>
  );
};
