'use client';
import Dev404Page from 'components/ui/Dev404page';
import { LOCALE } from 'constants/i18n';
import { LocalizationKey } from 'constants/i18n.messages';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { isLocale } from 'types/typeGuards';

const isDev = process.env.NODE_ENV === 'development';

type Props = {
  data: Record<
    LOCALE,
    {
      page: ReactNode;
      messages: Record<LocalizationKey, string>;
    }
  >;
};

export default function NotFound({ data }: Props) {
  const pathname = usePathname();

  const [firstSlug] = pathname.slice(1).split('/');
  const firstSlugIsLocale = isLocale(firstSlug);
  const locale = firstSlugIsLocale ? firstSlug : LOCALE.Fi;

  const page = data[locale].page;

  if (!page) {
    throw new Error('404 page not received from Contentful (see not-found.tsx)');
  }

  return isDev ? (
    <Dev404Page />
  ) : (
    <IntlProvider locale={locale} messages={data[locale].messages}>
      {page}
    </IntlProvider>
  );
}
