import { useCallback, useMemo } from 'react';

import { useStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

import { entries, fromEntries } from 'utils/object';

import initialFeatureFlags from 'data/initialFeatureFlags.json';
import { FeatureFlags } from './types';

export const useFeatureFlags = () => {
  const [storedFeatureFlags, setStoredFeatureFlags] = useStoredState(StorageKey.FeatureFlags);

  const featureFlags = useMemo(
    () => ({
      ...initialFeatureFlags,
      ...storedFeatureFlags,
    }),
    [storedFeatureFlags],
  );

  const setFeatureFlags = useCallback(
    (featureFlags: FeatureFlags) => {
      const modified = fromEntries(
        entries(featureFlags).filter(([key, value]) => value !== initialFeatureFlags[key]),
      );
      setStoredFeatureFlags(modified);
    },
    [setStoredFeatureFlags],
  );

  const clear = useCallback(() => {
    setStoredFeatureFlags(null);
  }, [setStoredFeatureFlags]);

  return [featureFlags, setFeatureFlags, clear] as const;
};
