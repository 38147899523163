import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import styles from './Pill.module.css';

type PillVariant =
  | 'yellow27'
  | 'grey15'
  | 'grey35'
  | 'grey40'
  | 'purple20'
  | 'blue20'
  | 'red10'
  | 'transparentGreen10'
  | 'transparentPurple20';

export interface PillProps extends HTMLAttributes<HTMLDivElement> {
  variant: PillVariant;
  compact?: boolean;
  allCaps?: boolean;
  noWrap?: boolean;
}

export const Pill = ({
  className,
  children,
  variant,
  compact,
  allCaps,
  noWrap,
  ...rest
}: PillProps) => {
  return (
    <span
      data-text="16"
      {...rest}
      className={classNames([
        styles.pill,
        className,
        {
          [styles.compact]: compact,
          [styles[variant]]: variant,
          [styles.allCaps]: allCaps,
          [styles.noWrap]: noWrap,
        },
      ])}
    >
      {children}
    </span>
  );
};
