'use client';
import { memo, ReactNode } from 'react';

import { ContentAccordion, IconProps } from '@vrfi/web-components';

import { ContentComponentProps } from 'types/contentComponent';

import DynamicIconLoad from '../DynamicIconLoad';
import styles from './ContentfulFoldingList.module.css';

const ContentfulFoldingListItemClientWrapper = ({
  content: { sys, fields },
  hideIcons,
  children,
}: ContentComponentProps<'foldingListRow'> & {
  hideIcons?: boolean;
  children?: ReactNode;
}) => {
  const iconName = fields.icon?.fields.icon;
  const icon = iconName
    ? (props: IconProps) => <DynamicIconLoad {...props} icon={iconName} />
    : undefined;

  return (
    <ContentAccordion
      key={sys.id}
      heading="h3"
      icon={hideIcons ? undefined : icon}
      label={<span data-text="heading5">{fields.title}</span>}
      description={fields.description}
      data-testid="toggle-row"
      data-analytics-id="toggle-row"
      id={`folding-list-${sys.id}`}
      panelClass={styles.trim}
      toggleClass={styles.button}
    >
      {!!children && children}
    </ContentAccordion>
  );
};
export default memo(ContentfulFoldingListItemClientWrapper);
