'use client';

import { CURRENCY_OPTIONS } from 'constants/i18n';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

type Props = { price?: number | null; children?: (formatted: string) => ReactNode };

export default function LocalizedPrice({ price, children }: Props) {
  const { formatNumber } = useIntl();

  const formatted = formatNumber(price ?? 0, CURRENCY_OPTIONS);

  return children ? children(formatted) : formatted;
}
