import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/global.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/font/local/target.css?{\"path\":\"src/components/structural/common/Layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../fonts/SuisseIntl-Regular-WebS.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../fonts/SuisseIntl-Medium-WebS.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../fonts/SuisseIntl-SemiBold-WebS.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"display\":\"block\"}],\"variableName\":\"suisse\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/font/local/target.css?{\"path\":\"src/components/structural/common/Layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../fonts/SuisseIntlMono-Bold-WebS.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"block\"}],\"variableName\":\"suisseMono\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/breakpointSlices.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/Accordion.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/AccordionToggle.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/ContentAccordion.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/Card.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/DialogForm.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/ScrollButtonContainer.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/Scroller.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/DirectionIndicator.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/Assembly.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/ServiceIcon.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/AssemblyCarriage.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/AssemblyTrain.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/ScrollButtons.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/AssemblyViewport.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/Errors.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/assets/CarriageFloor.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/button.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/articles/FeaturedArticles/ArticleItem/BackLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentComponentClientImporter"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentComponent/ContentComponentClientImporter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulFoldingList/ContentfulFoldingListItemClientWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulHighlightTextWithButton/ContentfulHighlightTextWithButtonModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulMenuList/ContentfulMenuList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulWagonMap/WagonMapWithServiceModal/index.ts");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/cssModules/Tab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizedMessage"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/i18n/LocalizedMessage/LocalizedMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/common/AppClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/common/NotFound.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/CommonRootComponents/CommonRootComponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptchaScript"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/ReCaptcha/ReCaptchaScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteDataClientProvider"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/SiteDataProvider/SiteDataClientProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/TranslationsProvider/ClientIntlProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/CookieConsent/CookieConsentSettings/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/grid/Col.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/grid/Container.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/grid/Row.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/Link/Link.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/Loading/Loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/PriceHighlightCircle/PriceHighlightCircle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreBadge"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/StoreBadge/StoreBadge.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/styles/global.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/baseButton.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/buttonColor.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/details.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/icon.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/line.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/row.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/stepNav.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/typography.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/verticalRadioSteps.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/margin.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/padding.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/content/RichText/EmbeddedAsset/EmbeddedAsset.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulContentHighlightWithStripes/ContentfulContentHighlightWithStripes.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/utils.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulTable/ContentfulTable.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/content/RichText/RichText.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulContentBoxWithFullwidthBackgroundImage/ContentfulContentBoxWithFullwidthBackgroundImage.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/DecorativeElement/DecorativeElement.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulDecorativeImageWithText/ContentfulDecorativeImageWithText.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulFactBoxes/ContentfulFactBoxes.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/articles/ArticleImage/ArticleImage.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/articles/FeaturedArticles/ArticleItem/ArticleItem.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/articles/FeaturedArticles/FeaturedArticles.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulFullGridImage/ContentfulFullGridImage.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulFullwidthBoxesWithBackground/ContentfulFullwidthBoxesWithBackground.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulFullwidthImageHeader/ContentfulFullwidthImageHeader.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulFullwidthImageWithInlineHighlight/ContentfulFullwidthImageWithInlineHighlight.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/HighlightCircle/HighlightCircle.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulHalfImageHalfText/ContentfulHalfImageHalfText.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/ErrorHeader/ErrorHeader.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulHighlightTextWithButton/ContentfulHighlightTextWithButton.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/NoticeBox/NoticeBox.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulInformationBulletList/ContentfulInformationBulletList.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulProductNavigation/ContentfulProductNavigation.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/Pill/Pill.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/styles/utils.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulProductNavigation/LinkBox.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulRichTextWithImage/ContentfulRichTextWithImage.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulThreeColumnRichTextCard/ContentfulThreeColumnRichTextCard.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulThreeColumnRichText/ContentfulThreeColumnRichText.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/Loading/ButtonLoading.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulTrainPunctuality/ContentfulTrainPunctuality.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulHighlightTextWithReference/ContentfulHighlightTextWithReference.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulTwoColumnInfo/ContentfulTwoColumnInfo.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/content/ContentfulFoldingList/ContentfulFoldingList.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/ui/TabLinks/TabLink.module.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/app/layout.css");
