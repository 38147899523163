'use client';
import { ComponentType, ReactNode } from 'react';

import { LOCALE } from 'constants/i18n';
import { ClientContentComponentPropsAnyContentType } from 'types/contentComponent';
import { ClientContentComponent } from './ClientContentComponent';

export const ContentComponentClientImporter: ComponentType<
  ClientContentComponentPropsAnyContentType & { children?: ReactNode; locale: LOCALE }
> = ({ children, ...props }) => {
  return <ClientContentComponent {...props}>{children}</ClientContentComponent>;
};
