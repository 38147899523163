import { useStoredState } from 'hooks/useStoredState';

import { StorageKey } from 'types/stateStore';

import initialFeatureFlags from 'data/initialFeatureFlags.json';
import { FeatureFlag } from './types';

type useFeatureFlag = (featureFlag: FeatureFlag) => boolean;

export const useFeatureFlag: useFeatureFlag = (featureFlag) => {
  const [featureFlags] = useStoredState(StorageKey.FeatureFlags);
  const initialValue = initialFeatureFlags[featureFlag] ?? false;
  const valueFromStore = featureFlags?.[featureFlag];
  return valueFromStore !== undefined ? valueFromStore : initialValue;
};
