import classNames from 'classnames';
import { ReactNode } from 'react';

import { Box } from 'components/cssModules';

import styles from './HighlightCircle.module.css';

interface Props {
  dark?: boolean;
  children: ReactNode;
  className?: string;
}

export const HighlightCircle = ({ className, dark, children, ...props }: Props) => (
  <Box
    as="div"
    titleColor={dark ? 'white' : 'var(--color-grey10)'}
    textColor={dark ? 'white' : 'var(--color-grey10)'}
    color={dark ? 'grey10' : 'white'}
    {...props}
    className={classNames(styles.circle, className)}
  >
    {children}
  </Box>
);
