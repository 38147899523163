import { ReactNode } from 'react';

import { FeatureFlag } from './types';
import { useFeatureFlag } from './useFeatureFlag';

export const FeatureFlagWrapper = ({
  featureFlag,
  children,
  showWhenDisabled = false,
}: {
  featureFlag: FeatureFlag;
  children: ReactNode;
  showWhenDisabled?: boolean;
}) => {
  const show = useFeatureFlag(featureFlag) !== showWhenDisabled;

  return show ? <>{children}</> : null;
};
