'use client';
import { createRef, memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@vrfi/web-components';
import { Modal } from 'components/ui/Modal/Modal';
import { ModalButtons } from 'components/ui/Modal/ModalButtons';

import { ContentPagesMessage } from 'constants/i18n.messages';

import { ContentComponentProps } from 'types/contentComponent';

import styles from './ContentfulHighlightTextWithButton.module.css';

const ContentfulHighlightTextWithButtonModal = ({
  children,
  ...props
}: ContentComponentProps<'highlightTextWithButton'>) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const ref = createRef<HTMLButtonElement>();
  const { buttonLabel, modalTitle } = props.content.fields;
  const { id } = props.content.sys;
  const buttonId = `modalButton__twoColumnInfo__${id}`;

  const toggleModal = useCallback(() => setIsOpen((isOpen) => !isOpen), []);

  return (
    <>
      {isOpen && (
        <Modal onClose={toggleModal} headerContent={modalTitle}>
          {({ onClose }) => (
            <>
              <div>{children}</div>
              <ModalButtons
                buttonOnClick={onClose}
                buttonContent={formatMessage({ id: ContentPagesMessage.CLOSE_MOBILE })}
              />
            </>
          )}
        </Modal>
      )}

      <Button
        ref={ref}
        className={styles.button}
        grey30
        data-testid="highlighted-text--button"
        onClick={toggleModal}
        id={buttonId}
      >
        {buttonLabel}
      </Button>
    </>
  );
};

export default memo(ContentfulHighlightTextWithButtonModal);
