import { ComponentProps, ReactNode } from 'react';

import { ButtonDefaultProps } from '@vrfi/web-components';

import { Button } from '@vrfi/web-components';

import { ButtonLoading } from '../Loading';
import { ModalRow } from './ModalRow';

interface Props {
  buttonContent?: ReactNode;
  buttonProps?: ButtonDefaultProps;
  buttonLoading?: boolean;
  buttonDisabled?: boolean;
  buttonOnClick?: () => void;
  secondaryButtonOnClick?: () => void;
  secondaryButtonContent?: ReactNode;
  secondaryButtonProps?: ButtonDefaultProps;
  spacing?: ComponentProps<typeof ModalRow>['spacing'];
}

export const ModalButtons = ({
  buttonContent,
  buttonOnClick,
  buttonProps,
  buttonLoading,
  buttonDisabled,
  secondaryButtonContent,
  secondaryButtonOnClick,
  secondaryButtonProps = {
    grey30: true,
  },
  spacing,
}: Props) => {
  if (!buttonContent && !secondaryButtonContent) {
    return null;
  }

  return (
    <ModalRow spacing={spacing}>
      {!!buttonContent && (
        <Button
          {...buttonProps}
          data-testid="modal__primary-action"
          stretch
          onClick={buttonOnClick}
          disabled={buttonDisabled}
        >
          <ButtonLoading loading={buttonLoading}>{buttonContent}</ButtonLoading>
        </Button>
      )}

      {!!secondaryButtonContent && (
        <Button
          {...secondaryButtonProps}
          data-testid="modal__secondary-action"
          stretch
          onClick={secondaryButtonOnClick}
        >
          {secondaryButtonContent}
        </Button>
      )}
    </ModalRow>
  );
};
