'use client';

import Link from 'components/ui/Link';
import { ReactNode } from 'react';
import { createRelativeUrlTo } from 'utils/route';
import styles from './ArticleItem.module.css';

type Props = { children: ReactNode; pathname: string; backLink?: string };
export default function BackLink({ children, pathname, backLink }: Props) {
  const resolvedPathname = pathname
    ? createRelativeUrlTo({ pathname, search: { back: backLink } })
    : undefined;

  return (
    <Link className={styles.link} href={resolvedPathname}>
      {children}
    </Link>
  );
}
